import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_14/b14_1.webp");
const image2 = require("../../../assets/images/blog_14/b14_2.webp");
const image3 = require("../../../assets/images/blog_14/b14_3.webp");
const image4 = require("../../../assets/images/blog_14/b14_4.webp");
const image5 = require("../../../assets/images/blog_14/b14_5.webp");
const image6 = require("../../../assets/images/blog_14/b14_6.webp");
const image7 = require("../../../assets/images/blog_14/b14_7.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Integrate and Automate your favorite apps with Dropbox using Workativ | Workativ Blog"
        description="Information workers have no shortage of tools at their disposal for collaboration – chat, email, online docs, video meetings, and more. These fixed-function tools have been an important first step for collaborative work, but they will not drive the exponential productivity gains enterprises are now looking for. There is a huge appetite for practical innovation"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Integrate and Automate your favorite apps with
                            Dropbox using Workativ
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Information workers have no shortage of tools at their
                          disposal for collaboration – chat, email, online docs,
                          video meetings, and more. These fixed-function tools
                          have been an important first step for collaborative
                          work, but they will not drive the exponential
                          productivity gains enterprises are now looking for.
                          There is a huge appetite for practical innovation and
                          taking management of work a step further.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Organizational leaders empower their workers to
                          achieve more by dramatically up-leveling the way they
                          collaborate with others – how they share, track,
                          update, and report on work. Simply adding more tools
                          to this equation is not the answer. Teams need the
                          ability to do more than communicate and share files;
                          they need a way to manage and execute work that drives
                          greater productivity and accountability.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The gains enterprises need will come largely from
                          automation, which allows workers to free up time spent
                          on repetitive, daily tasks and focus instead on work
                          that yields a much higher return for the business, as
                          well as providing greater job satisfaction.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Information workers need tools they can easily adapt
                          themselves to automate the great diversity of loosely
                          defined, unstructured work they perform every day. In
                          this way, we move away from fixed-function tools like
                          email and instead towards software that adapts to the
                          work at hand. This is where Workativ comes in.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ is a no-code platform for scaling your IT &
                          HR support with AI-based self-service automation.
                          Delivering remote support for employees is more
                          important than ever and Workativ can help automate
                          employee support with intelligent chatbot and process
                          automation, out-of-the-box. Workativ can be added to
                          any of your instant messaging apps like Slack or
                          Microsoft Teams so that employees can self-serve their
                          IT/HR issues, on-the-go, anytime.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          If your business is using a document management
                          software like Dropbox, it is high-time you integrated
                          it with Workativ to reap the benefits of automation.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          How Workativ streamlines key Dropbox processes
                        </h2>

                        <p class="font-section-normal-text line-height-2">
                          By integrating Dropbox and Workativ, you streamline
                          your workplace IT support to connect employees,
                          processes, and automations into a transparent
                          workflow. Let’s take a look at some of the automations
                          you get with Workativ and Dropbox integration.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1.Get File Link, Delete File
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ’s Dropbox process automation, employees
                          can easily access the file they need at the comfort of
                          their chat hubs like Slack or Microsoft Teams without
                          the need to log in to a static portal every time they
                          want to refer a document. And managers can delete a
                          file on Dropbox just by asking Workativ to do it for
                          them. Easy, right?
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Create, Delete and Get folder details
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s Dropbox process automation makes it easier
                          to create, delete, or retrieve details about a folder
                          in a jiff. This makes sure that files are organized
                          and easy to retrieve for employees on Workativ.
                        </p>
                        <img loading="lazy" src={image2} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          Popular Dropbox Integrations
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ, you can connect multiple apps and set
                          up an automation involving a synergistic workflow of
                          tasks on those apps. Let’s take a look at some of the
                          apps you can combine with Dropbox using Workativ.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Dropbox Azure AD Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s Dropbox integration with Microsoft Azure AD
                          helps you manage your Dropbox Business team centrally
                          through Microsoft Azure AD. When you use Dropbox with
                          Microsoft Azure AD, you can:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Configure single sign-on (SSO) for your Dropbox
                            Business team
                          </li>
                          <li>
                            Manage provisioning and deprovisioning Dropbox
                            Business users through Azure AD
                          </li>
                        </ul>

                        <p class="font-section-normal-text line-height-2">
                          You can set up an automation using Workativ such that,
                          whenever a new employee is onboarded on Azure AD, the
                          employee gets an invite automatically to join your
                          company’s Dropbox Business team. And similarly, when
                          there’s an outgoing employee, the employee’s details
                          get removed from Azure AD as well as from Dropbox
                          Business simultaneously.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Automate new Dropbox file to get emailed via Gmail
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          You can set up a Dropbox and Gmail automation using
                          Workativ such that, whenever there’s an important
                          product-related update to be shared with the team,
                          Workativ can fetch the document from Dropbox once it’s
                          uploaded by the product manager and share it with the
                          entire team by emailing the team via Gmail. Sounds so
                          simple, right?
                        </p>

                        <h5 class="font-section-sub-header-small">
                          3. Slack and Dropbox
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ’s Dropbox and Slack integration, your
                          content and conversations stay together seamlessly as
                          you move between tools – so your team and the teams
                          they work with can stay on the same page and find
                          information easily.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new employee being onboarded, help
                          desk agents can set up an automation on Workativ by
                          integrating Slack and Dropbox such that, the new
                          employee guidelines document from Dropbox gets shared
                          with the new employee on their Slack channel by
                          Workativ. This prevents the employee from going on a
                          searching spree on what are the company guidelines to
                          be followed.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          How to automate a file request in Dropbox using
                          Workativ
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Now that we’ve seen the endless possibilities of what
                          integrating Workativ and Dropbox and your other apps
                          can do for you, let’s see how you can go about the
                          same.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img loading="lazy" src={image6} className="mb-3"></img>
                        <img loading="lazy" src={image7} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Benefits
                      </h6>
                      <p class="font-section-normal-text line-height-2">
                        Now the benefits are enormous when it comes to
                        integrating and automating Dropbox tasks with Workativ.
                      </p>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Instant Document Lookups
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Documents and project materials can effortlessly be
                          viewed whenever required just by asking Workativ’s
                          Dropbox chatbot to bring up the document for you on
                          your Microsoft Teams or Slack app.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Enhanced Productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ’s Dropbox workflow automation solution offers
                          customizable templates that can be used to manage all
                          types of projects and corporate activities.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          These solutions boost productivity by eliminating
                          unnecessary work like manually logging in to static
                          portals and free up employee time for other tasks and
                          activities.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Dropbox Employee Self Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ provides users with a holistic self-service
                          interface for all of their IT needs. By integrating
                          Dropbox with Workativ, end users can retrieve and view
                          files/folders with ease. This integration saves time
                          and greatly enhances the user experience.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Easier Search Processes
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          One of the biggest advantages of workflow automation
                          systems is the ability to search by a wide range of
                          criteria for the information you need. Robust search
                          features make it much easier to locate previous
                          communications on a wide range of topics, including
                          the following:
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Billing and payment information for clients</li>
                          <li>Supply chain management data</li>
                          <li>
                            Previous projects with similar parameters to current
                            ones
                          </li>
                          <li>
                            Work performed by a specific individual or group
                          </li>
                          <li>
                            Chronological searches covering periods ranging from
                            an hour to many years
                          </li>
                          <li>
                            Information related to a specific client or job type
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          By choosing your parameters carefully, you can easily
                          and quickly access the data stored in your system.
                          This saves time and eliminates issues associated with
                          lost documents and data within your organization.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          Liked what you read about Workativ? Try Workativ today
                          by signing up for a FREE trial at workativ.com and
                          experience the difference yourself.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Dropbox Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="dropbox chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
